import React, { useState, useEffect } from 'react';
import { Routes, Route, Router } from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result';
import NotFound from './pages/NotFound';
import Blog from './pages/BlogDetail';
import BlogList from './pages/BlogList';
import NotFoundRD from './pages/NotFoundRD';

function App() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
  fetch('/posts.json')
    .then((response) => response.json())
    .then((data) => setPosts(data));
}, []);
  return (
      <Routes>
         <Route path='/' element={<Home />} />
         <Route path='/result' element={<Result />}  />
         <Route path="/blog/:id" element={<Blog posts={posts} />} />
         <Route path="/blog" element={<BlogList posts={posts}/>} />
         <Route path="/404" element={<NotFound />} />
         <Route path="/*" element={<NotFoundRD />} />
      </Routes>
  );
}

export default App;
