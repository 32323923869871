import React from 'react';
import { Link } from 'react-router-dom';
import '../blog.css'

function BlogList({ posts }) {
  return (
    <div className='blogList'>
      <h2 className='blogTitle'>게시글 목록</h2>
      <ul className='blogWrapper'>
        {posts.map((post) => (
          <li key={post.id} className='blogItem'>
            <Link className="blogItemA" to={`/blog/${post.id}`}>
              {post.title} - {post.author}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BlogList;
