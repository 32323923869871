import React from 'react';
import { useParams } from 'react-router-dom';
import '../blog.css'
import { Link } from 'react-router-dom';


function Blog({ posts }) {
  const { id } = useParams();
  const post = posts.find((post) => post.id === parseInt(id));

  if (!post) {
    return (posts.map((post) => (
      <li key={post.id}>
        <Link to={`/post/${post.id}`}>
          {post.title} - {post.author}
        </Link>
      </li>
    ))
  )
  }

  return (
    <div className='blogContent'>
      <div className='blogModal'>
        <h1>{post.title}</h1>
        <div className='blogPostDetail'>
          <p className='postDetail'>작성자: {post.author}</p>
          <p className='postDetail'>작성일: {post.date}</p>
        </div>
        <img src={post.img} alt='img.png'></img>
        <p className='content'>{post.content}</p>
      </div>
    </div>
  );
}

export default Blog;
