import './App.css';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import React from 'react';

function Home() {
    const [text, setText] = useState('');
    const navigate = useNavigate();
    const [alertOpen, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [inputLength, setInputLength] = useState(0);
    const [spinnerOpen, setSpinnerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      // 컴포넌트가 마운트될 때 이벤트 리스너 추가
      window.addEventListener('resize', handleResize);
  
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const onInputHandler = (e) => {
      setInputLength(e.target.value.length);
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        if (inputLength < 50){ setAlertMessage("세특은 최소 50자여야 합니다."); setAlert(true); return }
        setSpinnerOpen(true)
        const res = await fetch('https://api.myunjeop.site/api/generate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ arg: text }),
        }).then((response) => response.json())
        .then((data) => navigate("/result", { state: data}));
      } catch (error) {
        console.error('Error:', error);
      }
      
    };

  return (
    <>
    <div className="wrapper">
      {alertOpen && (
        <div className="alertBg">
          <div className="alertWrapper">
            <div className="alertTop">알림</div>
            <div className="alertMsg">{alertMessage}</div>
            <span onClick={ ()=> {setAlert(false)} } className="alertClose">닫기</span>
          </div>
        </div>
      )}
      {spinnerOpen && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="icon">📖</div>
      <div className="title">대입 면접 질문 생성기</div>
      <div className="explanation">학생부 종합전형중 일부는 생활기록부의 진위와 본인을 평가하기 위하여 면접을 거칩니다</div>
      <div className="explanation">면접을 대비하기 위해서는 세특을 바탕으로 면접관이 질문을 할 것으로 예상되는 질문을 만들고 답하는게 도움이 될 수 있습니다</div>
      <div className="explanation">이 사이트는 대입 면접 대비를 위해 세부능력 특기사항을 바탕으로 면접에 도움이 될 수 있는 예상 질문과 답변을 생성합니다</div>
      <div className="line"></div>
      <div className="modal center">
        <p className="subtitle">세부능력특기사항</p>
        <textarea id="seteuk"maxLength={ 700 } value={ text } onChange={ (e) => { setText(e.target.value ); onInputHandler(e) } } className="saeteuk center" placeholder="질문을 생성할 세특을 쓰세요"></textarea>
        <button className="create center" onClick={ handleSubmit }>생성하기</button>
      </div>
    </div>
  </>
  );
}

export default Home;
