import '../NotFound.css'
import { useNavigate } from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();
    const createQue = () => {
        navigate("/")
    }
    return (
        <>
            <div className='icon'>📖</div>
            <div className="title404">404 Not Found</div>
            <div className="explain">접속하신 페이지는 존재하지 않는 페이지 입니다.</div>
            <span onClick={ createQue } className='back'>메인 페이지로 돌아가기</span>
        </>
    );
}

export default NotFound;